import { Component, Input } from '@angular/core';
import { BaseAlgorithmClass } from '../base-algorithm-class';

@Component({
    selector: 'app-bc-parameters',
    templateUrl: './bc-parameters.component.html',
    styleUrls: ['../../shared/algorithm-parameters.scss'],
})
export class BcParametersComponent extends BaseAlgorithmClass {
    @Input() advancedSettingEnabled: boolean = false;

    constructor() {
        super();
    }
}
