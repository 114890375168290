import {
    Component,
    ElementRef,
    Input,
    OnInit,
    Output,
    ViewChild,
    EventEmitter,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-algorithm-input',
    templateUrl: './algorithm-input.component.html',
    styleUrls: ['./algorithm-input.component.scss'],
})
export class AlgorithmInputComponent implements OnInit {
    @Input() control: FormControl = new FormControl('');
    @Input() inputLabel: string = '';
    @Input() type: string = 'text';
    @Input() required: boolean = false;
    @Input() infoText: string = '';
    @Input() fixedPrefix: string = '';
    @Input() step: number;
    @Input() decimalPlaces: number = 6;
    @Input() hasSteppers: boolean = true;
    @Input() isFloatingPoint: boolean = true;
    @Input() defaultValue: string = '';
    @Input() allowEmptyValue: boolean = false;

    @Output() valueChange = new EventEmitter();

    @ViewChild('textInput') textInput: ElementRef;

    isFocused: boolean = false;
    forceFocus: boolean = false;
    separator: string = ',';

    private lastDecimalValue: string = '';
    private oldValue: any;

    constructor(private translate: TranslateService) {
        if (this.translate.currentLang === 'en') {
            this.separator = '.';
        }
        translate.onLangChange.subscribe((lang) => {
            if (lang.lang === 'en') {
                this.separator = '.';
                this.formatValue();
            } else {
                this.separator = ',';
                this.formatValue();
            }
        });
    }

    countDecimals(value): number {
        if (Math.floor(value) !== value)
            return Math.abs(Math.floor(Math.log10(Math.abs(value))));
        return 0;
    }

    ngOnInit(): void {
        if (!this.step) {
            if (this.isFloatingPoint) {
                this.step = parseFloat(
                    Math.pow(10, -this.decimalPlaces).toFixed(
                        this.decimalPlaces
                    )
                );
            } else {
                this.step = 1;
                this.decimalPlaces = 0;
            }
        }
        if (!this.control?.value) {
            return;
        }
        let tempFloatValue = parseFloat(this.control.value.replace(',', '.'));

        if (
            this.isFloatingPoint &&
            this.countDecimals(tempFloatValue) <= this.decimalPlaces
        ) {
            this.control.setValue(tempFloatValue.toFixed(this.decimalPlaces));
        }

        this.oldValue = this.control.value;
        if (this.control.value.includes(this.separator)) {
            this.lastDecimalValue = this.control.value.split(this.separator)[1];
        }
        this.formatValue();
    }

    inputFocused() {
        this.isFocused = true;
    }

    inputBlurred() {
        if (this.control.invalid) {
            this.isFocused = true;
            this.textInput.nativeElement.focus();
        } else {
            this.isFocused = false;
        }
    }

    blurInput() {
        this.textInput.nativeElement.blur();
    }

    updateValue() {
        if (!this.control.invalid) {
            this.formatOnBlur();
            this.oldValue = this.control.value;
            this.valueChange.emit();
        } else {
            this.isFocused = true;
            this.textInput.nativeElement.focus();
        }
    }

    getOldValue() {
        return this.oldValue;
    }

    cancelInput() {
        this.control.setValue(this.getOldValue());
        this.blurInput();
    }

    formatOnBlur() {
        let value = this.control.value + '';
        if (this.allowEmptyValue && value === '') {
            return;
        }
        if (!value && this.required === false) {
            this.resetLastStandInputValue();
            return;
        }
        value = value.replace(',', '.');
        value = value.replace('+', '');
        if (parseFloat(value) === 0) {
            value = value.replace('-', '');
            this.control.setValue(value);
            return;
        }
        let [base, floats] = value.split('.');
        if (floats === undefined || floats.length === 0) {
            if (this.isFloatingPoint) {
                this.step = 0.1;
                value = base + '.0';
            }
        }

        if (this.isFloatingPoint) {
            value = parseFloat(value).toFixed(this.decimalPlaces);
        }

        value = value.replace(',', this.separator);
        value = value.replace('.', this.separator);

        this.control.setValue(value);
    }

    private resetLastStandInputValue() {
        let result: string;
        if (this.defaultValue === this.oldValue) {
            result = this.defaultValue.replace(/[.,]/g, this.separator);
        } else {
            result = this.oldValue.replace(/[.,]/g, this.separator);
        }
        this.control.setValue(result);
    }

    formatValue() {
        if (this.isFloatingPoint) {
            let value = this.control.value + '';
            value = value.replace(',', '.');
            let [base, floats] = value.split('.');

            if (floats !== undefined && floats.length > 0) {
                if (floats.length > this.decimalPlaces) {
                    if (
                        this.lastDecimalValue &&
                        this.lastDecimalValue.length === this.decimalPlaces
                    ) {
                        floats = this.lastDecimalValue;
                    } else {
                        floats = floats.substring(0, this.decimalPlaces);
                    }
                }

                this.lastDecimalValue = floats.substring(0, this.decimalPlaces);
                this.control.setValue(
                    base + this.separator + this.lastDecimalValue
                );
            } else {
                let newValue = value.replace(',', this.separator);
                newValue = newValue.replace('.', this.separator);
                this.control.setValue(newValue);
            }
        }
    }

    forceInputBlur() {
        this.forceFocus = false;
    }

    changeValueWithStepper(operation: string) {
        if (!this.control.disabled) {
            this.forceFocus = true;
            this.textInput.nativeElement.focus();
            let value = this.control.value.toString();
            value = value.replace(',', '.');
            if (!value) {
                value = '0';
            }
            const numberValue = parseFloat(value);
            const newValue =
                operation === '+'
                    ? numberValue + this.step
                    : numberValue - this.step;
            let newValueString = newValue
                .toFixed(this.decimalPlaces)
                .replace('.', this.separator);

            this.control.setValue(newValueString);
            this.control.markAsDirty();
            this.control.updateValueAndValidity();
            if (
                this.control.valid &&
                this.textInput.nativeElement.value !== this.oldValue
            ) {
                this.updateValue();
            }
        }
    }
}
