import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { NgxFileDropEntry } from 'ngx-file-drop';
import {
    Observable,
    catchError,
    from,
    lastValueFrom,
    mergeMap,
    of,
    switchMap,
    tap,
    toArray,
} from 'rxjs';
import { ImgDbUploadUtilService } from './img-db-upload-util.service';
import { ImgUpload, QueueEntry, UploadErrorLog } from '../model/img-upload';
import { ToastMessageService } from 'src/app/shared/services/toast-message.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ImgDbUploadService {
    batchSize: number = 100;
    validatedFiles: File[] = [];
    maxConcurrentUpload = 3;
    uploadedCount = 0;
    interval = null;
    queue: Array<QueueEntry> = [];
    uploadProcessState: EventEmitter<void> = new EventEmitter<void>();

    private newInQueue: EventEmitter<void> = new EventEmitter<void>();
    private workerFinished: EventEmitter<void> = new EventEmitter<void>();
    private workerRunning: boolean;
    private uploadErrors: UploadErrorLog[] = [];
    private viewedImgDbId: string = '';

    constructor(
        private http: HttpClient,
        private imgDbUploadUtilService: ImgDbUploadUtilService,
        private translateService: TranslateService,
        private toastMessageService: ToastMessageService
    ) {
        this.workerRunning = false;
        this.newInQueue.subscribe(() => this.processEvent());
        this.workerFinished.subscribe(() => this.orchestrateWorker());
    }

    setViewedImgDbId(imgDbId: string) {
        this.viewedImgDbId = imgDbId;
    }

    addToUploadQueue(imgDbId: string, filesParam: NgxFileDropEntry[]) {
        //validated Files only for upload Queue
        this.imgDbUploadUtilService
            .validateAndCheckFiles(filesParam)
            .then((validFiles) => {
                if (validFiles?.length > 0) {
                    this.queue.push({ id: imgDbId, files: validFiles });
                    this.newInQueue.emit();
                }
            });
    }

    /**
     * Triggered when a new event/entry is added to queue
     */
    private processEvent(): void {
        if (!this.workerRunning) {
            // not running, start worker
            this.workerRunning = true;
            //Message that an upload started
            this.toastMessageService.showSuccessMsg(
                this.translateService.instant('imgDb.upload.uploadStartMessage')
            );
            this.orchestrateWorker();
        }
    }

    private orchestrateWorker(): void {
        if (this.queue.length > 0) {
            let entry = this.queue.shift();
            this.processInBatches(entry);
        } else {
            this.workerRunning = false;
            setTimeout(() => {
                this.toastMessageService.showSuccessMsg(
                    this.translateService.instant(
                        'imgDb.upload.uploadSuccessMessage'
                    )
                );
                this.cancelRefreshImages();
            }, 18000);
        }
    }

    /**
     * Process in small batches based on batchSize.
     * Is also the "Worker", which controls when it is done
     * @param entry QueueEntry
     */
    private async processInBatches(entry: QueueEntry) {
        let start = 0,
            end = this.batchSize;
        for (let i = entry.files.length / this.batchSize; i >= 0; i--) {
            let batchFiles: File[] = entry.files.slice(start, end);
            await this.processing(entry.id, batchFiles);
            this.showUploadErrors();
            start = end;
            end += this.batchSize;
        }

        this.workerFinished.emit();
    }

    private showUploadErrors() {
        if (this.uploadErrors.length > 0) {
            let errorMessage = this.translateService.instant(
                'imgDb.upload.error.failedUploadForFiles'
            );
            for (let errorLog of this.uploadErrors) {
                this.toastMessageService.showErrorMsg(
                    errorMessage +
                        `\n ${errorLog.file.name} ${errorLog.errorCode}`
                );
            }
            this.uploadErrors = [];
        }
    }

    private processing(imgDbId: string, files: File[]): Promise<any[]> {
        let obsBatch$ = this.imgDbUploadUtilService
            .generateSignedUrls(files, imgDbId)
            .pipe(switchMap((data) => this.uploadParallel(imgDbId, data)));
        return lastValueFrom(obsBatch$);
    }

    private uploadParallel(
        imgDbId: string,
        files: ImgUpload[]
    ): Observable<any[]> {
        let parallelUploads$ = from(files).pipe(
            mergeMap(
                (image: ImgUpload) =>
                    this.uploadFile(image.url, image.file).pipe(
                        catchError((err, caught) => {
                            console.error(err);
                            let errorCode;
                            if (err instanceof HttpErrorResponse) {
                                errorCode = err.status;
                            }
                            this.uploadErrors.push({
                                imgDbId,
                                file: image.file,
                                url: image.url,
                                errorCode,
                            } as UploadErrorLog);
                            return of();
                        })
                    ),
                this.maxConcurrentUpload
            ),
            tap(() => {
                this.uploadedCount++;
            }),
            toArray()
        );

        this.refreshImages(imgDbId);
        return parallelUploads$;
    }

    private uploadFile(url: string, file: File): Observable<any> {
        // CORS problem, if sent is aborted see google bucket CORS config
        return this.http.put(url, file, {
            headers: { 'Content-Type': 'image/jpeg' },
        });
    }

    private refreshImages(imgDbId: string) {
        if (!this.interval && this.viewedImgDbId === imgDbId) {
            this.setRefreshInterval();
        }
    }

    private setRefreshInterval() {
        this.interval = setInterval(() => {
            this.uploadProcessState.emit();
        }, 2000);
    }

    cancelRefreshImages() {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }
}
