import { HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';
import { CardPage } from '../../card/model/card-page';
import { SortBy } from '../enums/sortby.enum';

export class Pageable {
    page: number;
    pageSize: number;
    sortBy: string;
    asc: boolean;
    searchText: string;
    types: string;
}

export function createPageableByRouterParameters(
    pageable: Pageable,
    params: Params
): Pageable {
    pageable.page = params.page;
    pageable.pageSize = params.pageSize;
    pageable.sortBy = params.sortBy;
    pageable.asc = params.asc;
    pageable.searchText = params.searchText;
    pageable.types = params.types;

    if (pageable.page) {
        pageable.page = +pageable.page;
    } else {
        pageable.page = 1;
    }

    if (pageable.pageSize) {
        pageable.pageSize = +pageable.pageSize;
    }
    if (pageable.types === '') {
        pageable.types = undefined;
    }
    return pageable;
}

export function setDefaultWorkspaceSortingParameters(cardPage: CardPage) {
    cardPage.pageable.sortBy = SortBy.CREATED;
    cardPage.pageable.asc = false;
    cardPage.pageable.pageSize = 11;
}

export function httpByPageable(
    httpParams: HttpParams,
    pageable: Pageable
): HttpParams {
    return httpByPageableInfo(
        httpParams,
        pageable.page,
        pageable.pageSize,
        pageable.sortBy,
        pageable.asc,
        pageable.searchText,
        pageable.types
    );
}

function httpByPageableInfo(
    httpParams: HttpParams,
    page: number,
    pageSize: number,
    sortedBy: string,
    asc: boolean,
    searchText: string,
    types?: string
): HttpParams {
    if (searchText) {
        httpParams = httpParams.set('searchText', searchText);
    }
    if (page) {
        httpParams = httpParams.set('page', page + '');
    }
    if (pageSize) {
        httpParams = httpParams.set('pageSize', pageSize + '');
    }
    if (sortedBy !== undefined) {
        httpParams = httpParams.set('sortBy', sortedBy);
    }
    if (asc !== undefined) {
        httpParams = httpParams.set('asc', String(asc));
    }
    if (types !== undefined) {
        httpParams = httpParams.set('types', types);
    }
    return httpParams;
}

/**
 * Generic Paging Result.
 */
export class PagingResult<V> {
    values: V[];
    count: number;
    totalElements: number;
    totalPages: number;
}
