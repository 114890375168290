<div class="workspace-wrapper">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-12 px-0">
                <app-header-box
                    name="{{
                        'workspace.components.workspaceComponent.ownLibrary'
                            | translate
                    }}"
                    [showBackButton]="false"
                ></app-header-box>
            </div>
        </div>
    </div>
    <div class="container-fluid gallery-card-container">
        <div class="row">
            <ul
                class="nav-pills-cards"
                ngbNav
                #nav="ngbNav"
                [activeId]="selectedNavItem"
                (navChange)="changeSelectedNavItem($event)"
            >
                <li
                    *ngFor="let opt of workspacePages"
                    class="tab-item"
                    [ngbNavItem]="opt.value"
                >
                    <a class="tab-item-text" ngbNavLink>{{
                        opt.translationKey | translate
                    }}</a>
                    <ng-template ngbNavContent>
                        <app-project-library
                            [profilePicture]="profilePicture"
                            *ngIf="opt.value === 'projects'"
                        ></app-project-library>
                        <!-- <app-object-workspace-library
                            [profilePicture]="profilePicture"
                            *ngIf="opt.value === 'objects'"
                        ></app-object-workspace-library> -->
                        <!--Temporary commented due to LEA-1402-->
                        <!--<app-robot-library
                            [profilePicture]="profilePicture"
                            *ngIf="opt.value === 'robots'"
                        ></app-robot-library>
                        <app-scene-library
                            [profilePicture]="profilePicture"
                            *ngIf="opt.value === 'scenes'"
                        ></app-scene-library>-->
                        <!--Temporary commented due to LEA-633-->
                        <app-image-database-library
                            [profilePicture]="profilePicture"
                            *ngIf="opt.value === 'image-databases'"
                        ></app-image-database-library>
                    </ng-template>
                </li>
            </ul>
        </div>
    </div>
    <div class="container-fluid gallery-card-container">
        <div [ngbNavOutlet]="nav"></div>
    </div>
</div>
