import { Component, OnInit } from '@angular/core';
import * as fromAlgorithmForm from '../../../algorithm/models/algorithm-form';
import { AlgorithmType, AlgorithmDTO } from '../../models/algorithm-form';
import { FormControl, FormGroup } from '@angular/forms';
import { AlgorithmService } from '../../services/algorithm.service';
import { AlgorithmFormService } from '../../services/algorithm-form.service';
import { ActivatedRoute } from '@angular/router';
import { TrainingService } from 'src/app/shared/services/training.service';
import { TrainingDTO } from 'src/app/shared/models/training-dto';
import { TrainingStatus } from 'src/app/shared/models/training-status';
import { TrainingType } from '../../../shared/enums/training-type.enum';
import { forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { handleError } from '../../../shared/http-utilities';

@Component({
    selector: 'app-algorithm',
    templateUrl: './algorithm.component.html',
    styleUrls: ['./algorithm.component.scss'],
})
export class AlgorithmComponent implements OnInit {
    // html import
    protected readonly AlgorithmType = AlgorithmType;
    protected readonly TrainingType = TrainingType;

    dataLoaded: boolean = false;
    trainingId: string;
    editingIsDisabled: boolean = false;
    advancedSetting: boolean = false;
    form: FormGroup;

    algorithmTypes: fromAlgorithmForm.AlgorithmType[];

    type: FormControl<fromAlgorithmForm.AlgorithmType>;

    trainingName: string;
    trainingType: TrainingType;

    constructor(
        private algorithmService: AlgorithmService,
        private formService: AlgorithmFormService,
        private route: ActivatedRoute,
        private trainingService: TrainingService
    ) {}

    ngOnInit(): void {
        const trainingId = this.extractTrainingIdFromUrl();
        forkJoin([
            this.trainingService.get(trainingId),
            this.algorithmService.getAlgorithmFormData(trainingId),
        ])
            .pipe(catchError(handleError))
            .subscribe(([training, algorithm]: [TrainingDTO, AlgorithmDTO]) => {
                this.editingIsDisabled = training.status !== TrainingStatus.NEW;
                this.trainingName = training.name;
                this.trainingType = training.trainingType;
                this.setAlgorithmTypes(training.trainingType);
                this.initializeTypeForm(
                    algorithm.type,
                    training.status,
                    training.trainingType
                );
                this.initializeAlgorithmForm(algorithm);

                this.advancedSetting = algorithm.advancedSetting;
                this.dataLoaded = true;
            });
    }

    extractTrainingIdFromUrl() {
        return this.route.snapshot.paramMap.get('trainingId');
    }

    initializeTypeForm(
        value: AlgorithmType,
        trainingStatus: TrainingStatus,
        trainingType: TrainingType
    ) {
        this.type = new FormControl<AlgorithmType>(value);

        if (
            trainingStatus !== TrainingStatus.NEW ||
            trainingType === TrainingType.VISUAL
        ) {
            this.type.disable();
        }
    }

    initializeAlgorithmForm(data: AlgorithmDTO) {
        this.form = this.formService.getForm(data);
    }

    setAlgorithmTypes(type: TrainingType) {
        switch (type) {
            case TrainingType.MOTORIC:
                this.algorithmTypes = [
                    AlgorithmType.PPO,
                    AlgorithmType.SAC,
                    AlgorithmType.BC,
                ];
                break;
            case TrainingType.VISUAL:
                this.algorithmTypes = [AlgorithmType.ADAM];
                break;
            default:
                this.algorithmTypes = [];
        }
    }

    slideToggleChange($event) {
        if (!this.editingIsDisabled) {
            this.advancedSetting = $event.checked;
            this.onValueChange();
        }
    }

    onValueChange() {
        if (!this.editingIsDisabled) {
            const formData: AlgorithmDTO = this.prepareAlgorithmDto();

            this.adjustValuesForSaving(formData.parameter);

            this.algorithmService
                .saveAlgorithmFormData(
                    this.extractTrainingIdFromUrl(),
                    formData
                )
                .subscribe();
        }
    }

    onTypeValueChange() {
        if (!this.editingIsDisabled) {
            this.dataLoaded = false;
            this.advancedSetting = false;

            const formData: AlgorithmDTO = this.prepareAlgorithmDto(null);

            this.algorithmService
                .saveAlgorithmFormData(
                    this.extractTrainingIdFromUrl(),
                    formData
                )
                .subscribe((data: AlgorithmDTO) => {
                    this.initializeAlgorithmForm(data);
                    this.dataLoaded = true;
                });
        }
    }

    private prepareAlgorithmDto(
        parameter: Object = this.form.getRawValue()
    ): AlgorithmDTO {
        return {
            type: this.type.value,
            parameter: parameter,
            advancedSetting: this.advancedSetting,
        };
    }

    private adjustValuesForSaving(parameters: Object) {
        Object.keys(parameters).forEach((key: string) => {
            if (key === 'epsilon') {
                parameters[key] = Math.pow(10, parameters[key] as number);
            }

            let value = parameters[key];

            if (typeof value === 'string') {
                if (value.includes('.') || value.includes(',')) {
                    parameters[key] = parseFloat(
                        parameters[key].replace(',', '.')
                    );
                } else {
                    if (parameters[key] === '') {
                        parameters[key] = null;
                    } else {
                        const numeric = parseInt(parameters[key]);
                        if (!isNaN(numeric)) {
                            parameters[key] = numeric;
                        }
                    }
                }
            }
        });
    }
}
