import { Component } from '@angular/core';
import { ImageDatabaseAddCard } from '../../../card/model/add-card-implementations/image-database-add-card';
import { AuthenticationService } from '../../../authentication/services/authentication.service';
import { ImgDbService } from 'src/app/img-db/services/img-db.service';
import { Card } from 'src/app/card/model/card-implementations/card';
import { ImgDbCard } from './image-database.card';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Container } from '../../../shared/services/container/container.types';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseLibraryComponent } from '../base-library/base-library.component';

@Component({
    selector: 'app-image-database-library',
    templateUrl: './image-database-library.component.html',
})
export class ImageDatabaseLibraryComponent extends BaseLibraryComponent {
    constructor(
        private imgDbService: ImgDbService,
        private modalService: NgbModal,
        protected route: ActivatedRoute,
        protected authenticationService: AuthenticationService,
        protected router: Router
    ) {
        super(authenticationService, route, router);
    }

    convertToCard(container: Container, picture: string): Card {
        return ImgDbCard.convertToCard(container, picture);
    }

    createAddCard() {
        return new ImageDatabaseAddCard(this.modalService);
    }

    getData() {
        return this.imgDbService.getMyImageDbs(this.cardPage.pageable);
    }
}
