import { Injectable } from '@angular/core';

interface ColorObject {
    rgbaCode: string;
    opacity: string;
}

@Injectable({ providedIn: 'root' })
export class ColorService {
    private colors: ColorObject[] = [
        { rgbaCode: '0,0,255,1', opacity: '0.15' },
        { rgbaCode: '40,148,255,1', opacity: '0.15' },
        { rgbaCode: '148,148,148,1', opacity: '0.15' },
        { rgbaCode: '99,0,0,1', opacity: '0.15' },
        { rgbaCode: '206,0,0,1', opacity: '0.15' },
        { rgbaCode: '255,99,0,1', opacity: '0.25' },
        { rgbaCode: '255,189,0,1', opacity: '0.15' },
        { rgbaCode: '99,0,206,1', opacity: '0.25' },
        { rgbaCode: '148,0,148,1', opacity: '0.25' },
        { rgbaCode: '206,0,255,1', opacity: '0.25' },
        { rgbaCode: '0,80,40,1', opacity: '0.25' },
        { rgbaCode: '0,148,0,1', opacity: '0.15' },
        { rgbaCode: '0,208,0,1', opacity: '0.10' },
        { rgbaCode: '148,99,0,1', opacity: '0.25' },
        { rgbaCode: '206,162,99,1', opacity: '0.15' },
    ];

    private evaluationColors: { [key: string]: string } = {
        preTrain: '#ffffff',
        postTrain: '#4f4f4f',
        loss$val: '#e81010',
        loss$train: '#e81010',
        class_loss$val: '#0b1bd1',
        class_loss$train: '#0b1bd1',
        box_loss$val: '#048c1e',
        box_loss$train: '#048c1e',
        AP$val: '#e81010',
        sum$train: '#0b1bd1',
        return_mean$val: '#16cce2',
    };

    hashFunctionColorCodingSimpleScale(category: string) {
        let hash = 7;
        let numberOfColors = this.colors.length;

        for (let i = 0; i < category.length; i++) {
            let characterCode = Math.abs(parseInt(category.charAt(i), 36) - 9);
            //ignore space
            if (!category.charAt(i).includes(' ')) {
                //handle special characters
                if (Number.isNaN(characterCode)) {
                    hash += 99;
                } else {
                    hash += characterCode;
                }
            }
        }
        //Avoid that all words with a certain length get the same color
        if (category.length % numberOfColors === 0) {
            return Math.abs(hash * category.length - 1) % numberOfColors;
        } else {
            return (hash * category.length) % numberOfColors;
        }
    }

    getColor(category: string) {
        let colorCode =
            this.colors[this.hashFunctionColorCodingSimpleScale(category)]
                .rgbaCode;
        return this.getRgbaColor(colorCode);
    }

    getColorWithTransparency(categoryName: string) {
        let colorNode =
            this.colors[this.hashFunctionColorCodingSimpleScale(categoryName)];

        let parsedColorCode = colorNode.rgbaCode.split(',');
        parsedColorCode[parsedColorCode.length - 1] = colorNode.opacity;

        return this.getRgbaColor(parsedColorCode.join());
    }

    private getRgbaColor(colorCode: string) {
        return `rgba(${colorCode})`;
    }

    //placeholder for getting hardcoded colors in the future
    getColorForEvaluation(value: string) {
        return this.evaluationColors[value] || this.getColor(value);
        // TODO: define new colors for evaluation instead of using current map
    }
}
