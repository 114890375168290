import { Injectable } from '@angular/core';
import { VideoAndIterationFileService } from './video-and-iteration-file.service';
import { BehaviorSubject, mergeAll, mergeMap, toArray } from 'rxjs';
import { MessageService } from 'src/app/message/services/message.service';
import { ChartItem } from 'src/app/shared/components/charts/models/chart-item';
import { ContentItem } from '../models/side-container-data';

@Injectable()
export class IterationDataService {
    mapping = {
        modelUpdates: 'samples_Algorithm_modelUpdate',
        episodes: 'samples_Algorithm_episode',
        precisionRecallCurve: 'precision_recall',
        loss: 'eval',
        precision: 'eval',
        recall: 'eval',
        bcLoss: 'samples_Algorithm_batch_BC_',
        bcReturnMean: 'samples_Algorithm_batch_BC_',
    };
    costs: ChartItem[] = [];
    costOut: BehaviorSubject<ChartItem[]> = new BehaviorSubject<ChartItem[]>(
        []
    );
    selectedMetricIndices: number[] = [];

    constructor(
        private videoAndIterationFileService: VideoAndIterationFileService,
        private messageService: MessageService
    ) {}

    loadData(
        trainingId: string,
        maxNumberEpochs: number,
        selectedMetrics: ContentItem[],
        diagrammType: string
    ) {
        const prefix: string = this.mapping[diagrammType];
        this.costs = [];
        this.videoAndIterationFileService
            .getAllIterationFiles(trainingId, prefix)
            .pipe(
                mergeAll(),
                mergeMap((file: string) =>
                    this.videoAndIterationFileService.getIterationFile(
                        trainingId,
                        file
                    )
                ),
                toArray()
            )
            .subscribe({
                next: (fileContents) => {
                    if (fileContents.length > 0) {
                        let sortedFiles = this.sortAndPreprocessFiles(
                            fileContents,
                            maxNumberEpochs,
                            selectedMetrics
                        );
                        const curves =
                            this.extractPlotDataWithSelectedMetrics(
                                sortedFiles
                            );
                        curves.forEach(
                            (
                                curve: Array<{ x: number; y: number }>,
                                index: number
                            ) => {
                                let selectedMetric = selectedMetrics[index];
                                if (
                                    selectedMetric.value.startsWith('AP') ||
                                    selectedMetric.value.startsWith('AR')
                                ) {
                                    curve.forEach((point) => {
                                        point.y = point.y > 0 ? point.y : 0;
                                    });
                                }
                                this.costs.push({
                                    id: selectedMetric.value,
                                    label: selectedMetric.translationKey,
                                    points: curve,
                                    active: selectedMetric.checked,
                                });
                            }
                        );
                    }

                    this.costOut.next(this.costs);
                },
                error: () => {
                    this.messageService.displayTranslatedErrorMessage(
                        'evaluation.errors.loadData',
                        null
                    );
                },
            });
    }

    private sortAndPreprocessFiles(
        fileContents: string[],
        maxNumberEpochs: number,
        selectedMetrics: ContentItem[]
    ): string[][] {
        let sortedFiles = Array(fileContents.length);
        let rowsPerFile: number = 0;
        const stepSize = Math.max(maxNumberEpochs * 0.05, 1);
        this.selectMetricsForView(
            fileContents[0].split('' + '\n')[0],
            selectedMetrics
        );
        if (maxNumberEpochs < 0) {
            for (let i: number = 0; i < Math.min(2, fileContents.length); i++) {
                const splitFile: string[] = fileContents[i].split('' + '\n');
                // skip header line
                splitFile.shift();
                rowsPerFile = Math.max(rowsPerFile, splitFile.length);
            }
        }

        fileContents.forEach((file) => {
            let fileRows: string[] = file.split('\n');

            // skip header line in each file
            fileRows.shift();

            let iteration: number = +fileRows[0].substring(
                0,
                fileRows[0].indexOf(',')
            );
            if (maxNumberEpochs < 0) {
                sortedFiles[Math.floor(iteration / rowsPerFile)] = fileRows;
            } else {
                const index = Math.ceil((iteration - 1) / stepSize);
                sortedFiles[index > 0 ? index : 0] = fileRows;
            }
        });
        return sortedFiles;
    }

    private selectMetricsForView(
        headerRow: string,
        selectedMetrics: ContentItem[]
    ) {
        this.selectedMetricIndices = [];
        const splitHeader = headerRow.split(',');
        selectedMetrics.forEach((metric: ContentItem) => {
            const indexOfMetric = splitHeader.indexOf(metric.value);
            if (indexOfMetric > -1) {
                this.selectedMetricIndices.push(indexOfMetric);
            }
        });
    }

    private extractPlotDataWithSelectedMetrics(
        sortedFiles: string[][]
    ): Array<Array<{ x: number; y: number }>> {
        let extractedPlotData = [];
        this.selectedMetricIndices.forEach(() => {
            extractedPlotData.push([]);
        });

        sortedFiles.forEach((fileRows: string[]) =>
            fileRows.forEach((dataRow: string) => {
                if (!dataRow.length) {
                    return;
                }
                const splitDataRow = dataRow.split(',').map(Number);
                this.selectedMetricIndices.forEach(
                    (metricIndex: number, index: number) => {
                        extractedPlotData[index].push({
                            x: splitDataRow[0],
                            y: splitDataRow[metricIndex],
                        });
                    }
                );
            })
        );
        return extractedPlotData;
    }
}
