import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { ProjectAddCard } from 'src/app/card/model/add-card-implementations/project-add-card';
import { ProjectCard } from 'src/app/card/model/card-implementations/project-card';
import { ProjectService } from 'src/app/project/services/project.service';
import {
    Container,
    ContainerPage,
} from 'src/app/shared/services/container/container.types';
import { BaseLibraryComponent } from '../base-library/base-library.component';

@Component({
    selector: 'app-project-library',
    templateUrl: './project-library.component.html',
    styleUrls: ['./project-library.component.scss'],
})
export class ProjectLibraryComponent extends BaseLibraryComponent {
    constructor(
        private projectService: ProjectService,
        private modalService: NgbModal,
        protected route: ActivatedRoute,
        protected authenticationService: AuthenticationService,
        protected router: Router
    ) {
        super(authenticationService, route, router);
    }

    convertToCard(container: Container, picture: string) {
        return ProjectCard.convertToCard(container, picture);
    }

    createAddCard() {
        return new ProjectAddCard(this.modalService);
    }

    getData(): Observable<ContainerPage> {
        return this.projectService.getMyProjects(this.cardPage.pageable);
    }
}
